import { createContext, useContext } from 'react'
import { ClientConfig } from '../configClient'

let defaultConfig: ClientConfig = {
  clientUrl: 'https://yupty.live',
  mode: 'production',
  locale: 'en-GB',
}

export const ConfigContext = createContext<ClientConfig>(defaultConfig)

export const useConfig = () => {
  return useContext(ConfigContext)
}
